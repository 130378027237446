import { default as _91id_938qkGm9x2BlMeta } from "/opt/render/project/src/apps/admin/pages/brands/[id].vue?macro=true";
import { default as createZPeiZykfAiMeta } from "/opt/render/project/src/apps/admin/pages/brands/create.vue?macro=true";
import { default as indexEmrN8vrzn2Meta } from "/opt/render/project/src/apps/admin/pages/brands/index.vue?macro=true";
import { default as attributessX8zFpNmxbMeta } from "/opt/render/project/src/apps/admin/pages/bus-dossiers/[id]/attributes.vue?macro=true";
import { default as imagestwNnNGH5MCMeta } from "/opt/render/project/src/apps/admin/pages/bus-dossiers/[id]/images.vue?macro=true";
import { default as indexeefOzkCxTeMeta } from "/opt/render/project/src/apps/admin/pages/bus-dossiers/[id]/index.vue?macro=true";
import { default as _91id_93moWoFhqXUnMeta } from "/opt/render/project/src/apps/admin/pages/bus-dossiers/[id].vue?macro=true";
import { default as createYet1jbOl0nMeta } from "/opt/render/project/src/apps/admin/pages/bus-dossiers/create.vue?macro=true";
import { default as indexx2mkgIQI67Meta } from "/opt/render/project/src/apps/admin/pages/bus-dossiers/index.vue?macro=true";
import { default as dashboard_45demomhdFioofxYMeta } from "/opt/render/project/src/apps/admin/pages/dashboard-demo.vue?macro=true";
import { default as inboxnLaVjQ26HRMeta } from "/opt/render/project/src/apps/admin/pages/inbox.vue?macro=true";
import { default as indexzaCC7vTIAxMeta } from "/opt/render/project/src/apps/admin/pages/index.vue?macro=true";
import { default as loginy3TDq9ePx9Meta } from "/opt/render/project/src/apps/admin/pages/login.vue?macro=true";
import { default as _91id_93R7JkT0adzHMeta } from "/opt/render/project/src/apps/admin/pages/models/[id].vue?macro=true";
import { default as create26Xp5aCuV9Meta } from "/opt/render/project/src/apps/admin/pages/models/create.vue?macro=true";
import { default as index82vVCpCAqCMeta } from "/opt/render/project/src/apps/admin/pages/models/index.vue?macro=true";
import { default as indexeuhh8d7isvMeta } from "/opt/render/project/src/apps/admin/pages/settings/index.vue?macro=true";
import { default as members29urSlaPZSMeta } from "/opt/render/project/src/apps/admin/pages/settings/members.vue?macro=true";
import { default as notificationsAWZVIEP4o2Meta } from "/opt/render/project/src/apps/admin/pages/settings/notifications.vue?macro=true";
import { default as settingsScZH7MN4faMeta } from "/opt/render/project/src/apps/admin/pages/settings.vue?macro=true";
import { default as _91id_93MPlLpzUCK9Meta } from "/opt/render/project/src/apps/admin/pages/users/[id].vue?macro=true";
import { default as index26Xi6Emp1dMeta } from "/opt/render/project/src/apps/admin/pages/users/index.vue?macro=true";
import { default as _91id_937x8yvnRWOVMeta } from "/opt/render/project/src/apps/admin/pages/versions/[id].vue?macro=true";
import { default as create2oqSwadu08Meta } from "/opt/render/project/src/apps/admin/pages/versions/create.vue?macro=true";
import { default as indexj7f8SDEpgDMeta } from "/opt/render/project/src/apps/admin/pages/versions/index.vue?macro=true";
export default [
  {
    name: "brands-id",
    path: "/brands/:id()",
    component: () => import("/opt/render/project/src/apps/admin/pages/brands/[id].vue")
  },
  {
    name: "brands-create",
    path: "/brands/create",
    component: () => import("/opt/render/project/src/apps/admin/pages/brands/create.vue")
  },
  {
    name: "brands",
    path: "/brands",
    component: () => import("/opt/render/project/src/apps/admin/pages/brands/index.vue")
  },
  {
    name: _91id_93moWoFhqXUnMeta?.name,
    path: "/bus-dossiers/:id()",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-dossiers/[id].vue"),
    children: [
  {
    name: "bus-dossiers-id-attributes",
    path: "attributes",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-dossiers/[id]/attributes.vue")
  },
  {
    name: "bus-dossiers-id-images",
    path: "images",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-dossiers/[id]/images.vue")
  },
  {
    name: "bus-dossiers-id",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-dossiers/[id]/index.vue")
  }
]
  },
  {
    name: "bus-dossiers-create",
    path: "/bus-dossiers/create",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-dossiers/create.vue")
  },
  {
    name: "bus-dossiers",
    path: "/bus-dossiers",
    component: () => import("/opt/render/project/src/apps/admin/pages/bus-dossiers/index.vue")
  },
  {
    name: "dashboard-demo",
    path: "/dashboard-demo",
    component: () => import("/opt/render/project/src/apps/admin/pages/dashboard-demo.vue")
  },
  {
    name: "inbox",
    path: "/inbox",
    component: () => import("/opt/render/project/src/apps/admin/pages/inbox.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/render/project/src/apps/admin/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginy3TDq9ePx9Meta || {},
    component: () => import("/opt/render/project/src/apps/admin/pages/login.vue")
  },
  {
    name: "models-id",
    path: "/models/:id()",
    component: () => import("/opt/render/project/src/apps/admin/pages/models/[id].vue")
  },
  {
    name: "models-create",
    path: "/models/create",
    component: () => import("/opt/render/project/src/apps/admin/pages/models/create.vue")
  },
  {
    name: "models",
    path: "/models",
    component: () => import("/opt/render/project/src/apps/admin/pages/models/index.vue")
  },
  {
    name: settingsScZH7MN4faMeta?.name,
    path: "/settings",
    component: () => import("/opt/render/project/src/apps/admin/pages/settings.vue"),
    children: [
  {
    name: "settings",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/pages/settings/index.vue")
  },
  {
    name: "settings-members",
    path: "members",
    component: () => import("/opt/render/project/src/apps/admin/pages/settings/members.vue")
  },
  {
    name: "settings-notifications",
    path: "notifications",
    component: () => import("/opt/render/project/src/apps/admin/pages/settings/notifications.vue")
  }
]
  },
  {
    name: "users-id",
    path: "/users/:id()",
    component: () => import("/opt/render/project/src/apps/admin/pages/users/[id].vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/opt/render/project/src/apps/admin/pages/users/index.vue")
  },
  {
    name: "versions-id",
    path: "/versions/:id()",
    component: () => import("/opt/render/project/src/apps/admin/pages/versions/[id].vue")
  },
  {
    name: "versions-create",
    path: "/versions/create",
    component: () => import("/opt/render/project/src/apps/admin/pages/versions/create.vue")
  },
  {
    name: "versions",
    path: "/versions",
    component: () => import("/opt/render/project/src/apps/admin/pages/versions/index.vue")
  }
]