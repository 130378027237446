import revive_payload_client_YTGlmQWWT5 from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/opt/render/project/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/admin/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_2yIKADAg6h from "/opt/render/project/src/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/render/project/src/apps/admin/.nuxt/pwa-icons-plugin.ts";
import pwa_client_bU9rlafmKr from "/opt/render/project/src/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_jXOoAsp0hm from "/opt/render/project/src/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import slideovers_2NIjZ3dBbn from "/opt/render/project/src/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_FeYNAF2WLE from "/opt/render/project/src/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_mI6xh0VV9a from "/opt/render/project/src/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_x7So6BU7bH from "/opt/render/project/src/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_8brYH9QEed from "/opt/render/project/src/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_3WrHJn1XBW from "/opt/render/project/src/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_NBny7l3smW from "/opt/render/project/src/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_BKqLrMFqSU from "/opt/render/project/src/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import vueQuery_HbXfDZmQp2 from "/opt/render/project/src/apps/admin/plugins/vueQuery.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_2yIKADAg6h,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_bU9rlafmKr,
  plugin_jXOoAsp0hm,
  slideovers_2NIjZ3dBbn,
  modals_FeYNAF2WLE,
  colors_mI6xh0VV9a,
  plugin_client_x7So6BU7bH,
  plugin_8brYH9QEed,
  scrollbars_client_3WrHJn1XBW,
  presets_NBny7l3smW,
  variables_BKqLrMFqSU,
  vueQuery_HbXfDZmQp2
]